import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

// Components
import Input from '../form/Input';

// Misc
import * as v from '../../modules/validators';

import styles from '../../../stylesheets/public/components/Forms.module.postcss';

class ForgotPasswordForm extends Component {

  render() {
    const { handleSubmit, valid, submitting } = this.props;

    return (
      <form
        className={styles.FlexForm}
        onSubmit={handleSubmit}
      >
        <div className="Fieldset">
          <Field
            label="Email"
            name="email"
            component={Input}
            type="email"
            required
            validate={[v.required, v.email]}
          />

          <div className="FormRow">
            <button
              className="Button"
              type="submit"
              disabled={!valid || submitting}
            >
              Send Instructions
            </button>
          </div>


        </div>
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  valid: PropTypes.bool,
  submitting: PropTypes.bool
};

export default reduxForm({ form: 'register' })(ForgotPasswordForm);

