import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Link } from 'react-router-dom';
import ForgotPasswordForm from './ForgotPasswordForm';
import Meta from '../shared/Meta';

// Actions
import { requestPasswordReset } from '../../actions/authActions';

import styles from '../../../stylesheets/public/layouts/Register.module.postcss';

class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(data) {
    this.props.requestPasswordReset(data.email);
    this.form.reset();
  }

  render() {
    const { auth } = this.props;

    return (
      <div className={`${styles.Login} u-textCenter u-blockCenter u-limitWidth35 u-mb3 u-mt3`}>
        <Meta noindex />
        <h1 className="u-mb3">Forgot your password?</h1>
        <div className="Box Box--white u-textLeft">
          <div>
            <p>
              Enter your email address below and we'll send you password reset instructions.
            </p>

            {auth.passwordResetRequestFailed &&
              <div className={styles.FormMessages + " " + styles.FormMessages__error}>
                <span className="icon icon-warning" />
                <h4>
                  {auth.message}
                </h4>
              </div>
            }

            {auth.passwordResetRequested &&
              <div className={styles.FormMessages}>
                <span className="icon icon-check" />
                <h4>
                  Reset instructions sent! Check your email :)
                </h4>
              </div>
            }

            <div className="u-mb1">
              <ForgotPasswordForm
                ref={(c) => { this.form = c; }}
                onSubmit={this.onFormSubmit}
              />
            </div>

            <hr/>

            <div>
              <Link to="/login">Log in</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  auth: PropTypes.object,
  route: PropTypes.object,
  requestPasswordReset: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestPasswordReset: (email) => {
      dispatch(requestPasswordReset(email));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

